import Vue from "vue";
import Router from "vue-router";
Vue.use(Router)


let router = new Router({
	// mode: 'history',
	routes: [{
			path: '/',
			name: 'index',
			component: () => import('views/01zhihuichengshi.vue')
		},
		{
			path: '/04zhihuiwenlv',
			name: '智慧文旅',
			component: () => import('views/04zhihuiwenlv.vue')
		},
		// {
		// 	path: '/03zhihuisuyuan',
		// 	name: '智慧溯源',
		// 	component: () => import('views/03zhihuisuyuan.vue')
		// },
		// {
		// 	path: '/15zhihuichengguan',
		// 	name: '智慧城管',
		// 	component: () => import('views/15zhihuichengguan.vue')
		// },
		{
			path: '/01zhihuichengshi',
			name: '智慧城市',
			component: () => import('views/01zhihuichengshi.vue')
		},
		
		{
			path: '/02shuzixiangcun',
			name: '数字乡村',
			component: () => import('views/02shuzixiangcun.vue')
		},
		
		// {
		// 	path: '/11zhihuijiaotong',
		// 	name: '智慧交通',
		// 	component: () => import('views/11zhihuijiaotong.vue')
		// },
		{
			path: '/12zhihuijiaoyu',
			name: '智慧教育医疗',
			component: () => import('views/12zhihuijiaoyu.vue')
		},
		
		{
			path: '/16zhihuidangjian',
			name: '智慧党建',
			component: () => import('views/16zhihuidangjian.vue')
		},
		{
			path: '/17zhihuizhengwu',
			name: '智慧政务',
			component: () => import('views/17zhihuizhengwu.vue')
		},
		{
			path: '/18zongheguanli',
			name: '综合管理平台',
			component: () => import('views/18zongheguanli.vue')
		},
		{
			path: '/19xiangxianwenhua',
			name: '乡贤文化',
			component: () => import('views/19xiangxianwenhua.vue')
		},
		{
			path: '/05xinhengdianshang',
			name: '新亨电商',
			component: () => import('views/05xinhengdianshang.vue')
		},
		{
			path: '/11zhihuijiaotong',
			name: '智慧交通',
			component: () => import('views/11zhihuijiaotong.vue')
		},
		{
			path: '/20welcomJie',
			name: '揭阳欢迎页',
			component: () => import('views/20welcomJie.vue')
		},
		{
			path: '/20welcomSheng',
			name: '报业揭阳欢迎页',
			component: () => import('views/20welcomSheng.vue')
		},
		{
			path: '/storeDataScreen',
			name: '商城数据大屏',
			component: () => import('views/storeDataScreen.vue')
		},
		{
			path: '/map',
			name: 'map',
			component: () => import('views/map.vue')
		},
		{
			path: '/test',
			name: 'test',
			component: () => import('views/test.vue')
		},
		{
			path: '/videoTest',
			name: 'videoTest',
			component: () => import('views/videoTest.vue')
		}


	]
})
export default router;
